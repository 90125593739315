<template>
<div id="app">

    <v-row>

        <v-col cols="5" lg="2">
            <v-select :items="list_type_recherche" v-model="selecttype_recherche" dense outlined label="Type de recherche">

            </v-select>
        </v-col>

        <v-col lg="2" cols="5">
            <v-text-field v-model="libelle_recherche" label="Recherche..." dense outlined></v-text-field>

        </v-col>

        <v-col>
            <v-btn color="primary" dark class="mb-2" @click="readAll">
                Recherche
            </v-btn>

        </v-col>

        <v-col cols="5" lg="2">
            <v-select :items="list_pays" v-model="selectpays" dense outlined label="Liste des pays">
                <template slot="selection" slot-scope="data">
                    <v-img :src="data.item.logo" alt="data.item.id" width="50px"></v-img> {{ data.item.libelle }}
                </template>
                <template slot="item" slot-scope="data">
                    <v-img :src="data.item.logo" alt="data.item.id"></v-img> {{ data.item.libelle }}
                </template>

            </v-select>
        </v-col>

        <v-col v-if="null">
            <v-btn color="green" dark class="mb-2" @click="Actulisation()">
                Actualiser
            </v-btn>

        </v-col>

        <v-col>
            <v-btn color="green" dark class="mb-2" @click="open_dialog_add()">
                Ajouter SMS
            </v-btn>

        </v-col>

    </v-row>

    <v-data-table :headers="headers" :items="list" :search="search" hide-actions  class="elevation-1" color="primary" >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>LISTE DES SMS DU SERVEUR</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialog_add" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Formulaire</v-card-title>
                        <v-card-text>

                            <v-select :items="list_servers" v-model="selected_server" item-value="id" dense outlined label="Liste des monnaire" return-object @change="onServerChange">
                                <template slot="selection" slot-scope="data">
                                     {{ data.item.D }}
                                </template>
                                <template slot="item" slot-scope="data">
                                     {{ data.item.D }}
                                </template>
                            </v-select>

                            <v-text-field v-model="sms_amount" label="Montant" outlined></v-text-field>

                            <v-text-field v-model="sms_number" label="Numero" outlined></v-text-field>


                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="save_sms()">Ajouter</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialog" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Formulaire</v-card-title>
                        <v-card-text>

                            <v-select :items="list_monnaire" v-model="selectmonnaire" item-value="id" dense outlined label="Liste des monnaire">
                                <template slot="selection" slot-scope="data">
                                    <v-img :src="data.item.logo" alt="data.item.id" width="50px"></v-img> {{ data.item.libelle_monnaie }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    <v-img :src="data.item.logo" alt="data.item.id"></v-img> {{ data.item.libelle_monnaie }}
                                </template>

                            </v-select>
                            <v-text-field v-model="libelle_key" label="Code client" outlined></v-text-field>
                            <v-text-field v-model="libelle_adresse" label="Adresse" outlined></v-text-field>
                            <v-textarea v-model="observation" label="Raison" outlined></v-textarea>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="save()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>


                <v-dialog v-model="dialog2" max-width="400">

                    <v-card>
                        <v-card-title class="text-h5">Suppression</v-card-title>
                        <v-card-text>
                            <h3> Voulez vous vraiment supprimer blacklist ?</h3>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog2=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItem()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>

                </v-dialog>

            </v-toolbar>
        </template>
        <template v-slot:item.statut="{ item }">
            <v-switch v-model="item.statut" :color="item.statut == 1  ? 'green' : 'red' " :label="item.statut == 1  ? 'Confirmer' : 'Non confirmer'"></v-switch>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="detailsup(item)">
                <v-icon color="red" large class="mr-2">
                    mdi-delete
                </v-icon>
            </v-btn>
            <v-btn icon @click="detail(item)">
                <v-icon color="teal" large class="mr-2">
                    mdi-pencil
                </v-icon>
            </v-btn>

        </template>

    </v-data-table>

</div>
</template>

<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'

export default {
    name: "Gestion des clients",
    data: () => ({
        dialog: false,
        dialog2: false,
        search: "",
        detail_Formulaire: "",
        headers: [

            {

                text: "ID",
                align: "start",
                sortable: false,
                value: "id",
                divider: true,
                width: '10px'
            },
            {
                text: "Date",
                align: "start",
                sortable: false,
                value: "date",
                divider: true,
                width: '50px'
            },

            {

                text: "CODE SERVEUR",
                align: "start",
                sortable: false,
                value: "code_server",
                divider: true,
                width: '10px'
            },
            {

                text: "SIM",
                align: "start",
                sortable: false,
                value: "sim",
                divider: true,
                width: '20px'
            },
            {
                text: "Message",
                align: "start",
                sortable: false,
                value: "msg",
                divider: true,
                width: '350px'
            },
            {
                text: "Mode",
                value: "mode",
                divider: true,
                width: '10px'
            },

            {
                text: "Pays",
                value: "pays",
                divider: true,
                width: '10px'
            },
            {
                text: "Etat de sms",
                value: "statut",
                divider: true,
                width: '2%'
            },

        ],

        commentaire: '',
        selectstatus: '',
        detail_Formulaire: {
            nom: '',
            prenom: '',
            email: '',
            code: '',
            message: '',
        },

        list: [],
        list_type_recherche: ['CONFIRMER', 'NON CONFIRMER'],

        list_monnaire: [],

        dialog_add: false,
        sms_number: '',
        sms_amount: '',
        list_servers: [],
        selected_server: {},

        valeur: '',
        etat: false,
        libelle_recherche: '',

        id_transaction: '',
        libelle_key: '',
        libelle_adresse: '',
        observation: '',
        selectmonnaire: '',
        libelle_amount: '',
        id: '',
        etat: false,

    }),

    created() {
        this.readAll();
        this.readAll_monnaire();
        this.readAll_servers();
    },
    methods: {

        readAll: async function () {
            let fd = new FormData();
            fd.append('message', this.libelle_recherche);
            const data = await api.createUpdatedata('backoffice/all-sms', fd);
            this.list = data;
        },

        readAll_recherche: async function () {
            let fd = new FormData();
            fd.append('colonne', this.selecttype_recherche == 'CODE CLIENT' ? 'key' : 'email');
            fd.append('valeur', this.libelle_recherche);
            const data = await api.createUpdatedata('backoffice/all-blacklist', fd);
            this.list = data.data;
        },

        readAll_monnaire: async function () {
           /* let fd = new FormData();
            const data = await api.createUpdatedata('backoffice/all-monnaie', fd);
            this.list_monnaire = data;*/

            let fd2 = new FormData();
            const data2 = await api.createUpdatedata('backoffice/all-pays', fd2);
            this.list_pays = data2;

            //const data2= await api.createUpdatedata('backoffice/all-pays');

        },

        readAll_servers: async function () {
            const data = await api.readAll('backoffice/all-server');
            this.list_servers = data;
        },

        save_sms: async function () {
            let fd = new FormData();
            // sim, pays, msg, code_server, code_server, mode, statut,id_sms

            //console.log(this.selected_server.SMS_SENDER_D);
             var msg = this.selected_server.PAIEMENT_D_CLE_1 + ' Montant : ' + this.sms_amount +  ' du Numero : ' + this.sms_number+' Insertion manuelle';
            fd.append('sim', this.selected_server.SMS_SENDER_D);
            fd.append('pays', this.selected_server.pays);
            fd.append('msg', msg);
            fd.append('code_server', this.selected_server.code_server);
            fd.append('mode', 'MANUELLE');
            fd.append('statut', '0');
            fd.append('id_sms', 'NULL');

            const data = await api.createUpdatedata('backoffice/add-sms', fd);
            if (data.status == true) {
                this.showAlert(data.message, 'success');
                api.sendTelegram('New SMS Added : ' + msg);
                this.readAll();
                this.dialog_add = false;
            } else {
                this.showAlert(data.message, 'error');
            }
        },

        deleteItem: async function () {
            let fd = new FormData();

            const data = await api.createUpdatedata('backoffice/delete-blacklist/' + this.id, fd);
            if (data.status == true) {
                this.showAlert(data.message, 'success');
                this.readAll();
            } else {
                this.showAlert(data.message, 'error');
            }
        },
        clearItem() {
            this.selectmonnaire = 0;
            this.libelle_key = '';
            this.libelle_adresse = '';
            this.observation = '';

        },
        save: async function () {
            let fd = new FormData();
            fd.append('id_monnaire', this.selectmonnaire);
            fd.append('key', this.libelle_key);
            fd.append('adresse', this.libelle_adresse);
            fd.append('observation', this.observation);
            fd.append('amount', this.libelle_amount);

            if (this.etat == true) {
                const data = await api.createUpdatedata('backoffice/update-blacklist/' + this.id, fd);
                if (data.status == true) {
                    this.showAlert(data.message, 'success');
                    this.readAll();
                    this.dialog = false;
                    clearItem();
                } else {
                    this.showAlert(data.message, 'error');
                }
            } else {
                const data = await api.createUpdatedata('backoffice/add-blacklist', fd);
                if (data.status == true) {
                    this.showAlert(data.message, 'success');
                    this.readAll();
                    this.dialog = false;
                    clearItem();
                } else {
                    this.showAlert(data.message, 'error');
                }
            }

        },

        detail: async function (item) {
            this.id = item.id;
            this.observation = item.msg;
            this.selectmonnaire = item.currencie.id;
            this.libelle_key = item.user.key;
            this.libelle_adresse = item.account;
            this.etat = true;

            this.dialog = true;
        },
        detailsup: async function (item) {
            this.id = item.id;
            this.dialog2 = true;
        },

        open_dialog_add: async function () {
            this.dialog_add = true;
        },

        onServerChange: async function (item) {
            this.selected_server = item;
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },

        fetchData() {
            this.readAll();
        },

    },
};
</script>
